<template>
  <div>
    <div class="row">
      <!-- <div class="col-xs-12 col-sm-12 col-md-12 col-lg-8 col-xl-8"> -->
      <div class="col-12">
        <q-list bordered class="rounded-borders" style="border-top: 2px solid;">
          <q-expansion-item
            switch-toggle-side
            expand-separator
            dense
            header-class="bg-white text-black"
            icon="perm_identity"
            label="심사자"
          >
            <q-card>
              <q-card-section>
                <q-chip
                  v-for="(user, index) in action.auditTeams"
                  :key="index"
                  outline square 
                  color="primary"
                  text-color="white"
                  class="q-ma-none customchipdept">
                  <q-chip square
                    :color="user.roleCd === '1' ? 'deep-orange' : 'grey'"
                    text-color="white"
                    style="margin-left: -17px !important; height: 26px; margin-right: 5px !important;">
                    {{user.roleCd === '1' ? '심사팀장' : '심사자'}}
                  </q-chip>
                  {{user.userName}} / {{user.deptName}}
                </q-chip>
              </q-card-section>
            </q-card>
          </q-expansion-item>
        </q-list>
      </div>
    </div>
      <!-- :hideBottom="true" -->
    <c-table
      ref="table"
      title="내부심사 체크리스트"
      :columns="grid.columns"
      :data="action.checklistResult"
      :gridHeight="grid.height"
      :merge="grid.merge"
      :editable="editable&&!disabled"
      :isExcelDown="false"
      :filtering="false"
      :columnSetting="false"
      selection="multiple"
      rowKey="saiInternalAuditChecklistId"
      @innerBtnClicked="innerBtnClicked"
      @table-data-change="tableDataChange"
    >
      <!-- :pagePerRow="{pageNumber:100}" -->
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <c-btn v-if="editable&&!disabled" label="LBLREMOVE" icon="remove" @btnClicked="deleteChecklist" />
          <c-btn v-if="editable&&!disabled" label="LBLADD" icon="add" @btnClicked="addChecklist" />
          <c-btn v-if="editable&&!disabled" label="LBLSAVE" icon="save" @btnClicked="saveChecklist" />
        </q-btn-group>
      </template>
      <template slot="suffixTitle">
        <font class="text-negative" style="font-size:0.9em;font-weight:500;">
          ※ 수행일정을 선택하세요
        </font>
        <el-cascader
          placeholder="선택하세요"
          size="small"
          style="width:400px"
          :options="options"
          v-model="selectedSchedule"
          @change="handleChange">
        </el-cascader>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
// import selectConfig from '@/js/selectConfig';
export default {
  name: 'internal-action-info',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        id: '',
      }),
    },
    action: {
      type: Object,
      default: () => ({
        id: '',  // 일련번호
        plantCd: '',
        col1: '', // 심사구분
        col2: '', // 심사 준비 요청사항
        col3: '', // 심사 목적 및 범위
        regDtStr: '',
        regUserName: '',
        auditMonthDepts: [], // 심사일정 - 피심사팀에 따른
        auditTeams: [], // 심사팀
        distributeTeams: [], // 배포부서
        checklistResult: [],
      }),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    contentHeight: null,
  },
  data() {
    return {
      selectedSchedule: [],
      grid: {
        merge: [
          { index: 0, colName: 'saiSystemElementsCd' },
        ],
        columns: [
          {
            name: 'saiSystemElementsName',
            field: 'saiSystemElementsName',
            label: '관련표준',
            align: 'center',
            style: 'width:100px',
            sortable: false,
            innerBtn: true,
            btns: [
              { label: '', icon: 'add', color: 'light-blue', value: '1', tooltip: '심사 항목 추가' },
            ],
          },
          // {
          //   name: 'sortOrder',
          //   field: 'sortOrder',
          //   label: '순서',
          //   align: 'center',
          //   style: 'width:30px',
          //   sortable: false,
          // },
          {
            name: 'itemName',
            field: 'itemName',
            label: '심사 항목',
            align: 'left',
            style: 'width:450px',
            sortable: false,
            type: 'html',
          },
          {
            name: 'aimMatter',
            field: 'aimMatter',
            label: '비고',
            align: 'left',
            style: 'width:200px',
            sortable: false,
            type: 'html',
          },
          {
            name: 'result',
            field: 'result',
            label: '내부심사결과',
            child: [
              {
                name: 'sar0000001',
                field: 'sar0000001',
                label: '적합',
                align: 'center',
                style: 'width:40px',
                sortable: false,
                type: 'check',
                checkIcon: 'task_alt',
                uncheckIcon: 'radio_button_unchecked',
                'true': 'Y',
                'false': 'N',
                color: 'green', 
              },
              {
                name: 'sar0000005',
                field: 'sar0000005',
                label: '부적합',
                align: 'center',
                style: 'width:40px',
                sortable: false,
                type: 'check',
                checkIcon: 'task_alt',
                uncheckIcon: 'radio_button_unchecked',
                'true': 'Y',
                'false': 'N',
                color: 'green', 
              },
              // {
              //   name: 'sar0000010',
              //   field: 'sar0000010',
              //   label: '관찰사항',
              //   align: 'center',
              //   style: 'width:40px',
              //   sortable: false,
              //   type: 'check',
              //   checkIcon: 'task_alt',
              //   uncheckIcon: 'radio_button_unchecked',
              //   'true': 'Y',
              //   'false': 'N',
              //   color: 'green', 
              // },
              // {
              //   name: 'sar0000015',
              //   field: 'sar0000015',
              //   label: '비적용',
              //   align: 'center',
              //   style: 'width:40px',
              //   sortable: false,
              //   type: 'check',
              //   checkIcon: 'task_alt',
              //   uncheckIcon: 'radio_button_unchecked',
              //   'true': 'Y',
              //   'false': 'N',
              //   color: 'green', 
              // },
            ]
          },
          {
            name: 'observation',
            field: 'observation',
            // label: '관찰내용<br/>(부적합사항<br/>및 보고서No.)',
            label: '관찰내용',
            align: 'left',
            style: 'width:250px',
            sortable: false,
            type: 'textarea',
          },
        ],
        data: [],
        height: '780px',
      },
      popupOptions: {
        isFull: false,
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
      editable: true,
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    options() {
      let _options = [];
      if (this.action.auditMonthDepts && this.action.auditMonthDepts.length > 0) {
        let _deptCdMap = this.$_.uniq(this.$_.map(this.action.auditMonthDepts, 'deptCd'))
        // let _deptNameMap = this.$_.uniq(this.$_.map(this.action.auditMonthDepts, 'deptName'))
        this.$_.forEach(_deptCdMap, deptCd => {
          let deptName = this.$_.find(this.action.auditMonthDepts, { deptCd: deptCd }).deptName;
          let filterdata = this.$_.filter(this.action.auditMonthDepts, { deptCd: deptCd });
          let _childrend = [] // this.$_.filter(this.action.auditMonthDepts, { deptCd: deptCd });
          this.$_.forEach(filterdata, item => {
            _childrend.push({
              value: `${item.date} ${item.times[0]} ~ ${item.times[1]}`,
              label: `${item.date} ${item.times[0]} ~ ${item.times[1]}`,
            })
            // item.value = `${item.date} ${item.times[0]} ~ ${item.times[1]}`
            // item.label = `${item.date} ${item.times[0]} ~ ${item.times[1]}`
          })
          _options.push({
            deptCd: deptCd,
            deptName: deptName,
            value: deptCd,
            label: deptName,
            children: _childrend
          })
        })
      }
      return _options;
    }
  },
  watch: {
    // options: {
    'action.auditMonthDepts': {
      handler: function () {
        if (this.options && this.options.length > 0) {
          this.selectedSchedule = [this.options[0].value, this.options[0].children[0].value]
        }
      },
      deep: true,
    },
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
    },
    /* eslint-disable no-unused-vars */
    handleChange(value) {
      // 이후 변경에 맞추어 data를 filtering하는 작업이 필요
    },
    innerBtnClicked(col, props, btn) {
    },
    tableDataChange(props, col) {
      // let blackList = ['itemName', 'aimMatter', 'observation']
      let whiteList = ['sar0000001', 'sar0000005', 'sar0000010', 'sar0000015']
      if (whiteList.indexOf(col.name) > -1 && props.row[col.name] === 'Y') {
        this.$_.forEach(whiteList, item => {
          if (item === col.name) return
          this.$set(props.row, item, 'N')
        })
      }
    },
    addChecklist() {

    },
    deleteChecklist() {
      window.getApp.$emit('CONFIRM', {
        title: 'LBLCONFIRM',
        message: 'MSGREMOVE', // 삭제하시겠습니까?,
        // TODO : 필요시 추가하세요.
        type: 'info', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    saveChecklist() {
      window.getApp.$emit('CONFIRM', {
        title: 'LBLCONFIRM',
        message: 'MSGSAVE', // 저장하시겠습니까?
        // TODO : 필요시 추가하세요.
        type: 'info', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          // this.$_.forEach(this.node.hazopScenarios, item => {
          //   item.regUserId = this.$store.getters.user.userId
          //   item.chgUserId = this.$store.getters.user.userId
          // })
          
          // this.isSave = !this.isSave
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    }
  }
};
</script>